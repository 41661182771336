<template>
  <section :class="$style.container">
    <div :class="$style.inner">
      <loader v-if="loading" center />
      <div v-else-if="error" :class="$style.error" class="absolute-center">
        <div>{{ $t("SpeakerQuestions.errorQuestions") }}</div>
        <ws-button color="primary" outlined @click="$emit('refresh')">
          {{ $t("common.update") }}
        </ws-button>
      </div>
      <slot v-else />
    </div>
  </section>
</template>

<script>
import Loader from "@/components/common/elements/Loader";
import WsButton from "@/components/base/WsButton";

/**
 * Представление для вопросов спикеру
 *
 * @vue-prop {boolean} loading - отображение загрузки
 * @vue-prop {boolean} error - отображение при возникновении ошибки
 * @vue-event refresh - евент для попытки загрузить заного
 */
export default {
  name: "LayoutSpeakerQuestions",
  components: { WsButton, Loader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 15px 13px;
  overflow: auto;

  @include mq-tablet {
    padding: 30px 0;
  }
}

.inner {
  max-width: 561px;
  margin: 0 auto;

  & > *:first-child {
    margin-bottom: 19px;

    @include mq-tablet {
      margin-bottom: 31px;
    }
  }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > *:first-child {
    margin-bottom: 15px;
  }
}
</style>
