<template>
  <div :class="$style.container">
    <div v-if="!questions.length" class="absolute-center">
      {{ $t("SpeakerQuestions.noQuestions") }}
    </div>
    <div v-else :class="$style.list">
      <template v-for="question in questions">
        <div v-if="question.isDivider" :key="question.id" :class="$style.divider">
          {{ $t("SpeakerQuestions.hiddenQuestions") }}:
        </div>
        <question-item
          v-else
          :key="question.id"
          :class="[$style.list__item, { [$style.list__item_hidden]: !question.isModerated }]"
          :question="question"
          @visible="$emit('set-moderation', question)"
          @answer="$emit('set-answer', question)"
          @show-answer-dialog="$emit('show-answer-dialog', question)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import QuestionItem from "@/components/speaker/questions/QuestionItem";

/**
 * Лист вопросов содержит в себе разделитель скрытых и открытых вопросов
 *
 * @vue-prop {Array} question - массив вопросов может содержать обьект разделителя
 */
export default {
  name: "QuestionList",
  components: {
    QuestionItem,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" module>
.divider {
  margin-bottom: 15px;
}

.list {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }

  &__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &_hidden {
      opacity: 0.6;
    }
  }
}
</style>