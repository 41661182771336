<template>
  <smart-modal :visible="visible" class="speaker-answer-modal" @close="sendClose">
    <template slot="header">{{ $t("SpeakerQuestions.answerModal.title") }}</template>

    <template slot="body">
      <b class="title">{{ $t("SpeakerQuestions.answerModal.question") }}</b>
      <span v-for="(row, index) in editableQuestion.messages" :key="index">{{ row }}</span>
      <b>{{ $t("SpeakerQuestions.answerModal.questionAnswer") }}</b>
      <textarea v-model.trim="editableQuestion.answer"></textarea>
    </template>

    <template slot="footer">
      <ws-button
        block
        outlined
        color="primary"
        lg
        class="smart-modal__button smart-modal__button--cancel"
        @click="sendClose"
      >
        {{ $t("common.cancel") }}
      </ws-button>

      <ws-button
        block
        color="primary"
        class="smart-modal__button smart-modal__button--confirm"
        lg
        :disabled="!editableQuestion.answer"
        @click="answerAtQuestion"
      >
        {{ $t("SpeakerQuestions.answerModal.submitButton") }}
      </ws-button>
    </template>
  </smart-modal>
</template>

<script>
import axios from "axios";
import SmartModal from "@/components/common/modals/SmartModal";
import WsButton from "@/components/base/WsButton";
import config from "@/settings/config";

export default {
  name: "QuestionAnswerModal",
  components: {
    SmartModal,
    WsButton,
  },

  props: {
    // Параметр видимости модального окна ответа на вопрос.
    visible: {
      type: Boolean,
      default: false,
    },
    // Вопрос на который дают ответ.
    editableQuestion: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    /**
     * Метод сохранения письменного ответа на вопрос.
     */
    answerAtQuestion() {
      this.editableQuestion?.answer &&
        this.editableQuestion?.id &&
        axios
          .post(
            `${config.serverUrl}/web/api/speakerTextAnswer`,
            {
              answer: this.editableQuestion?.answer,
            },
            {
              params: {
                question_id: this.editableQuestion?.id,
              },
            },
          )
          .then(() => {
            this.$emit("answer");
            this.sendClose();

            this.$notify({
              group: "broadcast",
              type: "success",
              duration: 2000,
              title: this.$t("SpeakerQuestions.notifs.answer.success.title"),
              text: this.$t("SpeakerQuestions.notifs.answer.success.text"),
            });
          })
          .catch(res => {
            this.$notify({
              group: "broadcast",
              type: "error",
              duration: 2000,
              title: this.$t("SpeakerQuestions.notifs.answer.error.title"),
              text: this.$t("SpeakerQuestions.notifs.answer.error.text"),
            });

            console.warn(res);
          });
    },
    /**
     * Метод передачи события закрытия модального окна.
     */
    sendClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.title {
  color: #858585;
}

.speaker-answer-modal {
  .smart-modal__panel {
    &-body {
      display: flex;
      flex-direction: column;
      padding: 15px 0;

      & > b {
        margin: 10px 0;
      }

      textarea {
        border: solid 1px orange;
        border-radius: 5px;
        resize: none;
        width: 100%;
        padding: 10px;
        height: 150px;
      }
    }
  }
}
</style>
