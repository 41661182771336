<template>
  <layout-speaker-questions :loading="loading" :error="error" @refresh="init">
    <question-header
      :moderation="availableRoomModules.questionPreModeration"
      @toggle-moderation="togglePremoderation"
      @select-sort="setSort"
      @select-filter="setFilter"
    />

    <question-list
      :questions="sortedQuestions"
      @set-moderation="moderateQuestion"
      @set-answer="answerQuestion"
      @show-answer-dialog="(showAnswerModal = true), (editableQuestion = $event)"
    />

    <question-answer-modal
      :visible="showAnswerModal"
      :editableQuestion="editableQuestion"
      @close="showAnswerModal = false"
      @answer="setQuestionAnswer"
    />
  </layout-speaker-questions>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { room, speakerQuestions } from "@/store/modules/store.namespaces";
import {
  ANSWER_ON_QUESTION,
  GET_QUESTIONS,
  MODERATE_QUESTION,
  TOGGLE_PREMODERATION,
} from "@/store/modules/speaker/modules/questions/action-types";
import { AVAILABLE_ROOM_MODULES, ROOM_NUMBER } from "@/store/modules/common/getter-types";
import redirectNotMainSpeakerMixin from "@/components/speaker/mixins/redirectNotMainSpeakerMixin";
import QuestionHeader from "@/components/speaker/questions/QuestionHeader";
import LayoutSpeakerQuestions from "@/components/layouts/LayoutSpeakerQuestions";
import QuestionList from "@/components/speaker/questions/QuestionList";
import QuestionAnswerModal from "@/components/speaker/questions/QuestionAnswerModal";

const INTERVAL_UPDATE_QUESTIONS = 3000;

/**
 * Спикер. Страница вопросов спикеру
 *
 * @vue-data {boolean} loading - Статус инициализации вопросов
 * @vue-data {boolean} error - Статус ошибки при инициализации вопросов
 * @vue-data {number|null} interval - хранит ид интервала для его уничтожения
 * @vue-data {string} currentSort - текущая сортировка влияет на запрос получения вопросов
 * @vue-data {string} currentFilter - текущий филтр для вопросов
 * @vue-data {Array} questions - вопросы из стора
 * @vue-data {string} roomNumber - содержит номер текущей комнаты
 * @vue-data {object} availableRoomModules - доступные модули фронта
 * @vue-data {Array} filteredQuestions - отфильтрованные вопросы стора по текущему фильтру
 * @vue-data {Array} sortedQuestions - сортировка по модерации вопроса
 */
export default {
  name: "ViewQuestions",
  components: {
    LayoutSpeakerQuestions,
    QuestionHeader,
    QuestionList,
    QuestionAnswerModal,
  },
  mixins: [redirectNotMainSpeakerMixin],
  data() {
    return {
      loading: false,
      error: false,
      interval: null,
      currentSort: "mark",
      currentFilter: "all",
      // Признак показа модального окна
      showAnswerModal: false,
      // Редактируемый спикером вопрос
      editableQuestion: {},
    };
  },
  computed: {
    ...mapState(speakerQuestions, {
      questions: "questions",
    }),
    ...mapGetters(room, {
      roomNumber: ROOM_NUMBER,
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    filteredQuestions() {
      if (this.currentFilter === "answer") {
        return this.questions.filter(q => q.isAnswered);
      }
      return [...this.questions];
    },
    sortedQuestions() {
      const moderated = this.filteredQuestions.filter(q => q.isModerated);
      const notModerated = this.filteredQuestions.filter(q => !q.isModerated);
      if (notModerated.length) {
        notModerated.unshift({ isDivider: true, id: "divider" });
      }
      return [...moderated, ...notModerated];
    },
  },
  watch: {
    roomNumber() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.clearQuestionRequesters();
  },
  methods: {
    ...mapActions(speakerQuestions, {
      getSpeakerQuestions: GET_QUESTIONS,
      togglePremoderation: TOGGLE_PREMODERATION,
      moderateQuestion: MODERATE_QUESTION,
      answerSpeakerQuestion: ANSWER_ON_QUESTION,
    }),
    /**
     * Инициализация компоненты
     *
     * @returns {Promise<void>}
     */
    async init() {
      try {
        this.error = false;
        this.loading = true;
        await this.getSpeakerQuestions(this.currentSort);
        this.setQuestionRequesters();
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    /**
     * Установить текущий фильтр
     *
     * @param {string} val - режим фильтрации
     */
    setFilter(val) {
      this.currentFilter = val;
    },
    /**
     * Установить текущую сортировку
     *
     * @param {string} val - режим сортировки
     */
    setSort(val) {
      this.getSpeakerQuestions(val);
      this.currentSort = val;
    },
    /**
     * Установить авто запросы за вопросами
     */
    setQuestionRequesters() {
      this.clearQuestionRequesters();
      this.interval = setInterval(() => {
        this.getSpeakerQuestions(this.currentSort);
      }, INTERVAL_UPDATE_QUESTIONS);
    },
    /**
     * Удалить интервал запросов за вопросами
     */
    clearQuestionRequesters() {
      if (!this.interval) return;
      clearInterval(this.interval);
    },
    /**
     * Снять или поставить отметку "отвеченно", показать диалог в случае если не Лэптоп
     *
     * @param {object} question - вопрос на который надо ответить
     * @returns {Promise<void>}
     */
    async answerQuestion(question) {
      if (!this.$mqLaptop && question.isAnswered) {
        await this.showDialog();
      }
      this.answerSpeakerQuestion(question);
    },
    /**
     * Показать диалог для подтверждения действия
     *
     * @returns {Promise<void>}
     */
    showDialog() {
      return this.$dialog.confirm(
        {
          title: this.$t("SpeakerQuestions.dialogTitle"),
          body: this.$t("SpeakerQuestions.dialogBody"),
        },
        {
          okText: this.$t("SpeakerQuestions.dialogOkText"),
          cancelText: this.$t("SpeakerQuestions.dialogCancelText"),
        },
      );
    },
    /**
     * Костыль из-за запроса на получение списка вопросов по интервалу.
     * TODO: Исправить получение списка вопросов и убрать этот костыль.
     */
    setQuestionAnswer() {
      const question = this.questions.find(question => {
        return question.id === this.editableQuestion?.id;
      });

      question.isAnswered = true;
      question.answer = this.editableQuestion?.answer;
    },
  },
};
</script>
