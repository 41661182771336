<template>
  <header :class="$style.container">
    <ws-toggle :value="moderation" reverse @input="$emit('toggle-moderation', $event)">
      <span :class="$style.switchText">{{ $t("SpeakerQuestions.moderate") }}</span>
    </ws-toggle>

    <div :class="$style.sort">
      <ws-select
        :class="$style.dropdown"
        :options="sortOptions"
        :value="sortType"
        :min-width="140"
        @input="handleSelectSort"
      />
      <ws-icon-select :options="answeredSort" :value="questionsOrder" @input="handleSelectOrder">
        <ws-icon regular lg color="primary">sliders-v</ws-icon>
      </ws-icon-select>
    </div>
  </header>
</template>

<script>
import WsToggle from "@/components/base/WsToggle";
import WsIconSelect from "@/components/base/WsIconSelect";
import WsSelect from "@/components/base/WsSelect";
import WsIcon from "@/components/base/WsIcon";

const createOption = (label, id) => ({ label, id });

export default {
  name: "QuestionHeader",
  components: {
    WsToggle,
    WsIconSelect,
    WsSelect,
    WsIcon,
  },
  props: {
    moderation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortOptions: [
        createOption(this.$t("SpeakerQuestions.sortByLikes"), "mark"),
        createOption(this.$t("SpeakerQuestions.sortByTime"), "date"),
      ],
      answeredSort: [
        createOption(this.$t("SpeakerQuestions.displayAllQuestions"), "all"),
        createOption(this.$t("SpeakerQuestions.displayAnsweredQuestions"), "answer"),
      ],
      sortType: {
        label: this.$t("SpeakerQuestions.sortByLikes"),
        id: "mark",
      },
      questionsOrder: {
        label: this.$t("SpeakerQuestions.displayAllQuestions"),
        id: "all",
      },
    };
  },
  methods: {
    handleSelectSort(sort) {
      this.sortType = sort;
      this.$emit("select-sort", sort.id);
    },
    handleSelectOrder(order) {
      this.questionsOrder = order;
      this.$emit("select-filter", order.id);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.switchText {
  font-size: 1.1em;
  color: $gray-dark;

  @include mq-tablet {
    color: $black-light;
  }
}

.sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  @include mq-tablet {
    justify-content: flex-start;
    width: auto;
    margin-top: 0;
    margin-left: auto;
  }

  & > *:first-child {
    margin-right: 30px;
  }
}

.dropdown {
  width: 140px;
}
</style>
