<template>
  <article :class="$style.container">
    <div :class="$style.description">
      <span
        v-for="(stroke, index) in question.messages"
        :key="index"
        :class="$style.description__stroke"
      >
        {{ stroke }}
      </span>
    </div>

    <div :class="$style.answer">
      <p v-if="question.answer" class="flex flex-column">
        <b>{{ $t("SpeakerQuestions.title.speakerAnswer") }}</b>
        {{ question.answer }}
      </p>

      <p v-if="question.admin_answer" class="flex flex-column">
        <b>{{ $t("SpeakerQuestions.title.managerAnswer") }}</b>
        {{ question.admin_answer }}
      </p>
    </div>

    <div :class="$style.footer">
      <ws-button color="primary" icon :outlined="!!question.isModerated" @click="$emit('visible')">
        <ws-icon>{{ !question.isModerated ? "eye" : "eye-slash" }}</ws-icon>
      </ws-button>

      <ws-button
        color="primary"
        :outlined="!question.isAnswered || !!question.answer"
        :disabled="!!question.answer"
        @click="$emit('answer')"
      >
        <ws-icon>user-check</ws-icon>
        {{ $mqMobile ? $t("SpeakerQuestions.answered") : $t("SpeakerQuestions.answeredVerbally") }}
      </ws-button>

      <ws-button color="primary" :outlined="!question.answer" @click="$emit('show-answer-dialog')">
        <ws-icon>comment</ws-icon>
        {{ $mqMobile ? "" : $t("SpeakerQuestions.answeredInWriting") }}
      </ws-button>

      <ws-button
        color="primary"
        icon
        outlined
        :disabled="!question.answer"
        @click="clearQuestionAnswer(question.id)"
      >
        <ws-icon>far fa-trash-alt</ws-icon>
      </ws-button>

      <div />
      <div :class="$style.footer__like">
        <ws-icon light>thumbs-up</ws-icon>
        <span>{{ question.countMark }}</span>
      </div>
      <div :class="$style.footer__like">
        <ws-icon light>thumbs-down</ws-icon>
        <span>{{ question.countDislike }}</span>
      </div>
    </div>
  </article>
</template>

<script>
import SpeakerQuestion from "@/models/SpeakerQuestion";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import axios from "axios";
import config from "@/settings/config";

/**
 * Вопрос спикеру
 *
 * @vue-prop {SpeakerQuestion} question - Вопрос содержит все данные вопроса
 * @vue-event visible - Клик по кнопки модерации вопроса
 * @vue-event answer - Клик по кнопке устного ответа на вопрос
 */
export default {
  name: "QuestionItem",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    question: {
      type: SpeakerQuestion,
      default: () => new SpeakerQuestion(),
    },
  },
  methods: {
    /**
     * Метод очистки ответа на вопрос.
     *
     * @param {number} question_id - id вопроса, в котором очищается ответ.
     */
    clearQuestionAnswer(question_id) {
      question_id &&
        axios
          .get(`${config.serverUrl}/web/api/clearSpeakerAnswer`, {
            params: {
              question_id,
            },
          })
          .then(() => {
            this.question.isAnswered = false;
            this.question.answer = "";
          });
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: 15px;
  border: 1px solid rgba($black, 0.1);
  border-radius: 5px;
  background: $white;
  transition: 300ms;

  &:hover {
    box-shadow: 0 2px 15px rgba($black, 0.15);
  }
}

.description {
  display: grid;
  grid-gap: 5px;
  margin: 0 0 15px;
  line-height: 19px;
  font-size: 16px;
  color: $black-light;
}

.answer {
  margin: 15px 0;
}

.footer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(7, auto);
  align-items: center;

  &__like {
    margin: 0;
    color: $orange;

    & > *:first-child {
      margin-right: 5px;
    }
  }
}
</style>
