<template>
  <label
    :class="[$style.container, { [$style.container_reverse]: reverse }]">
    <div :class="switchClasses">
      <input
        :class="$style.input"
        type="checkbox"
        :checked="value"
        @change="$emit('input', $event.target.checked)"
      />
      <span :class="$style.slider" />
    </div>
    <div v-if="$scopedSlots.default" :class="$style.slot">
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  name: "WsToggle",
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchClasses() {
      return [this.$style.switch, this.currentSizeClass];
    },
    currentSizeClass() {
      return (this.lg && this.$style.lg) || this.$style.md;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  --ws-toggle-padding: 3px;
  --ws-toggle-height-md: 24px;
  --ws-toggle-height-lg: 34px;
  --ws-toggle-height: var(--ws-toggle-height-md);
  --ws-toggle-width: calc((var(--ws-toggle-height) - 6px) * 2 + 6px);
  --ws-toggle-dot-width: calc(var(--ws-toggle-height) - 6px);
  --ws-toggle-dot-height: var(--ws-toggle-dot-width);

  display: flex;
  align-items: center;
  user-select: none;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }

  &_reverse {
    flex-direction: row-reverse;
  }
}

.slot {
}

.switch {
  position: relative;
  display: inline-block;
  width: var(--ws-toggle-width);
  height: var(--ws-toggle-height);
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: $gray;
  border-radius: var(--ws-toggle-dot-width);
  transition: 0.3s;

  &::before {
    position: absolute;
    top: 3px;
    left: 3px;
    width: var(--ws-toggle-dot-width);
    height: var(--ws-toggle-dot-height);
    content: "";
    background-color: $white;
    border-radius: 50%;
    transition: 0.3s;
  }
}

.input {
  width: var(--ws-toggle-width);
  height: var(--ws-toggle-height);
  opacity: 0;

  &:checked + .slider {
    background-color: var(--base-color);

    &::before {
      transform: translateX(var(--ws-toggle-dot-width));
    }
  }
}

/* sizes */
.md {
  --ws-toggle-height: var(--ws-toggle-height-md);
}

.lg {
  --ws-toggle-height: var(--ws-toggle-height-lg);
}
</style>